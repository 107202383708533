<template>
  <div class="mobile_news_info_container" v-if="info.id">
    <div class="title">{{info.title}}</div>
    <div class="intro">
      <div class="time">{{dateFormat(info.issue_time, 'y-m-d')}}</div>
      <div class="look_num">
        <img src="/static/img/mobile/eye.png">
        {{info.look_num}}
      </div>
    </div>
    <div class="content" v-html="info.content"></div>

    <div class="action">
      <div :class="{has:info.pre != 0}" @click="skipInfo(info.pre)">
        <img src="/static/img/mobile/news/pre.png">
        上一篇
      </div>
      <div :class="{has:info.next != 0}" @click="skipInfo(info.next)">
        下一篇
        <img src="/static/img/mobile/news/next.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    };
  },

  created() {
    this.getInfo(this.$route.query.id);
  },
  methods: {
    getInfo(id) {
      this.$api("/official/page/news_info", {
        id: id,
      }).then(({ data }) => {
        this.info = data;

        this.wechatShareInfo({
          title:this.info.title,
          desc:this.stripHTML(this.info.content).substr(0, 150),
          img_url:this.info.cover,
          link:location.origin + "/?news_info=" + id
        });
      });
    },
    skipInfo(id) {
      if (!id) {
        return;
      }
      this.getInfo(id);
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile_news_info_container {
  margin: 77px 96px 100px 96px;
  .title {
    font-size: 69px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 96px;
    font-weight: bold;
  }

  .intro {
    margin-top: 42px;

    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 61px;

    display: flex;

    .look_num {
      img {
        width: 54px;
        height: 35px;
      }
      margin-left: 77px;
    }
  }

  .content {
    margin-top: 80px;
    // padding-bottom: 300px;
    width: 100%;
    overflow: hidden;

    ::v-deep img {
      max-width: calc(100% - 38px);
      padding: 20px 19px;
    }
    ::v-deep p {
      font-size: 58px;
      font-weight: 400;
      color: #333333;
      line-height: 123px;
      margin: 0 !important;
      br {
        display: none;
      }
      span {
        font-family: PingFangSC-Regular, PingFang SC !important;
      }
    }
  }

  .action {
    margin-top: 123px;
    margin-bottom: 307px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 69px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-right: 96px;
      }

      opacity: 0.2;
      &.has {
        opacity: 1;
      }

      img {
        width: 104px;
        height: 104px;
        margin: 0 38px;
      }
    }
  }
}
</style>